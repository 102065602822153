import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../styles/main.scss'
import kamienicaImg from '../images/adam-swiecki-kamienica.jpg'
import AdamImg from '../images/adam-swiecki-kontakt.png'
import AnimateComponent from '../components/animate-component'

const ContactPage = (props) => (
  <Layout>
    <SEO title="Kontakt | Świecki Kancelaria" />
        <AnimateComponent styleName="contact-page">
            <div className='contact-page__container wow fadeInUp'>
                <div className="contact-page__top-container">
                    <div className="contact-page__photo-container">
                        <img className="contact-page__photo" src={kamienicaImg} alt="biała, łodzka kamienica - siedziba firmy"/>
                    </div>
                    <div className="contact-page__map" id="googlemap">
                        <div className="contact-page__contact-data contact-data contact-data--top">
                        {props.data.wordpressPage.acf.adresy.slice(0, 1).map(( adres,i ) => (
                            <div key={i} className="contact-data__city-box">
                                {adres.lokalizacja !== null &&
                                <h3>
                                    {adres.lokalizacja}
                                </h3>
                                }
                                {adres.adres !== null &&
                                <div className="contact-data__row">
                                    <p>Adres</p>
                                    <p dangerouslySetInnerHTML={{ __html: `${adres.adres}` }} />
                                </div>
                                }
                                {adres.telefon !== null &&
                                <div className="contact-data__row">
                                    <p>Telefon</p>
                                    <p dangerouslySetInnerHTML={{ __html: `${adres.telefon}` }} />
                                </div>
                                }
                                {adres.email !== null &&
                                <div className="contact-data__row">
                                    <p>E-mail</p>
                                    <p><a href={`mailto:${adres.email}`} dangerouslySetInnerHTML={{ __html: `${adres.email}` }} /></p>
                                </div>
                                }
                                {adres.spotkania !== null &&
                                <div className="contact-data__row">
                                    <p>Spotkania</p>
                                    <p dangerouslySetInnerHTML={{ __html: `${adres.spotkania}` }} />
                                </div>
                                }
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
                <div className="contact-page__contact-data contact-data contact-data--bottom">
                    <div className="contact-data__col contact-data__col--left" >
                        <p className="contact-data__greeting">
                            Zapraszam na spotkanie i rozmowę przy kawie.
                        </p>
                        <img className="contact-data__photo" src={AdamImg} alt=""/>
                    </div>
                    <div className="contact-data__col contact-data__col--right" >
                    {props.data.wordpressPage.acf.adresy.slice(1, 99).map(( adres,i ) => (
                        <div key={i}className="contact-data__city-box">
                            {adres.lokalizacja !== null &&
                                <h3>
                                    {adres.lokalizacja}
                                </h3>
                            }
                            {adres.adres !== null &&
                                <div className="contact-data__row">
                                    <p>Adres</p>
                                    <p dangerouslySetInnerHTML={{ __html: `${adres.adres}` }} />
                                </div>
                            }
                            {adres.telefon !== null &&
                                <div className="contact-data__row">
                                    <p className="contact-data__label">Telefon</p>
                                    <p className="contact-data__value contact-data__value--green" dangerouslySetInnerHTML={{ __html: `${adres.telefon}` }} />
                                </div>
                            }
                            {adres.spotkania !== null &&
                                <div className="contact-data__row">
                                    <p>Spotkania</p>
                                    <p dangerouslySetInnerHTML={{ __html: `${adres.spotkania}` }} />
                                </div>
                            }
                        </div>
                    ))}
                    </div>
                </div>
                </div>
        </AnimateComponent>
  </Layout>
)

export default ContactPage
export const query = graphql`
query {
    site {
      siteMetadata {
        title
      }
    }
    wordpressPage( slug: {eq: "kontakt"}) {
      id
      title
      content
      excerpt
      date
      modified
      slug
      status
      acf {
        adresy {
          lokalizacja
          telefon
          email
          adres
          spotkania
        }
      }
    }
}`